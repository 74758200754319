
import {defineComponent} from "vue";
import RouterNavigate from "@/components/base/common/RouterNavigate.vue";
import InfoBox from "@/components/base/InfoBox.vue";
import {useHelpStore} from "@/store/help.store";
import OptOut from "@/components/base/OptOut.vue";
import HelpEmbed from "@/components/base/HelpEmbed.vue";
import {useQuickActionStore} from "@/store/quick.action.store";

export default defineComponent({
  name: "TaskLayout",
  components: {HelpEmbed, OptOut, RouterNavigate, InfoBox},
  setup() {
    const helpStore = useHelpStore();
    helpStore.setHelpId('0L53QGBuW')
    const quickActionStore = useQuickActionStore();
    return{
      quickActionStore,
    }
  }
})
